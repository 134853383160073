import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import styled from 'styled-components';

const breakpoints = {
  mobile: '430px',
  tablet: '768px',
};

const StyledDiv = styled.div`
  border: 1px solid black;
  margin-inline: 2rem;
`;

const StyledHeadingWrapper = styled.div`
  padding: 1rem 2rem;
`;

const StyledHeading = styled.h1`
  font-size: 4rem;
  font-family: Montserrat-ExtraBold;
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 40%;
  padding-left: 2rem;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const StyledLabel = styled.label`
  padding-top: 1rem;
  font-size: 1.3rem;
`;

const StyledField = styled(Field)`
  margin-block: 8px;
  border: 1px solid black;
  border-radius: 5px;
  height: 1.5rem;
  width: 100%;

  &:focus {
    border: 2px solid #f4bfdb;
    transition: 0.3s ease-in;
    outline: none;
  }
`;

const StyledTextarea = styled.textarea`
  border: 1px solid black;
  border-radius: 5px;
  height: 4rem;
  margin-block: 8px;

  &:focus {
    border: 2px solid #f4bfdb;
    transition: 0.3s ease-in;
    outline: none;
  }

  @media (max-width: 768px) {
    height: 5rem;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
`;

const StyledButton = styled.button`
  padding: 0.6rem 3rem;
  background-color: transparent;
  cursor: pointer;
  border-radius: 2rem;
  border: 1px solid black;
  color: black;

  &:hover {
    background-color: #56876d;
    color: white;
    transition: 0.3s ease-in;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.mobile}) {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ContactForm = () => {
  const handleSubmit = async (values, actions) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      comments: values.comments,
    };
    console.log(data); // for debugging purposes only

    try {
      const response = await axios.post(
        'https://formspree.io/f/mvonlged',
        data
      );

      if (response.status === 200) {
        alert('Thank you for your submission!');
      } else {
        alert('Something went wrong. Please try again later.');
      }
    } catch (error) {
      console.error(error);
      alert('Something went wrong. Please try again later.');
    }

    actions.setSubmitting(false);
    actions.resetForm();
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('required'),
    lastName: Yup.string().required('required'),
    email: Yup.string().email('Invalid email').required('required'),
    comments: Yup.string().required('required'),
  });

  return (
    <>
      <StyledDiv />
      <Container>
        <StyledHeadingWrapper>
          <StyledHeading>let's chat</StyledHeading>
        </StyledHeadingWrapper>
        <StyledFormWrapper>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              comments: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '5%',
                  }}
                >
                  <div>
                    <StyledLabel htmlFor="firstName">first name:</StyledLabel>
                    <StyledField type="text" name="firstName" id="firstName" />
                    <StyledErrorMessage name="firstName" />
                  </div>

                  <div>
                    <StyledLabel htmlFor="lastName">last name:</StyledLabel>
                    <StyledField type="text" name="lastName" id="lastName" />
                    <StyledErrorMessage name="lastName" />
                  </div>

                  <div style={{ gridColumn: 'span 2' }}>
                    <StyledLabel htmlFor="email" paddingTop="1rem">
                      e-mail:
                    </StyledLabel>
                    <StyledField type="email" name="email" id="email" />
                    <StyledErrorMessage name="email" />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <StyledLabel htmlFor="comments" paddingTop="1rem">
                    message:
                  </StyledLabel>
                  <Field as={StyledTextarea} name="comments" id="comments" />
                  <StyledErrorMessage name="comments" />
                </div>

                <div
                  style={{
                    width: '100%',
                    textAlign: 'flex-start',
                    paddingBlock: '2rem',
                  }}
                >
                  <StyledButton type="submit" disabled={isSubmitting}>
                    send
                  </StyledButton>
                </div>
              </Form>
            )}
          </Formik>
        </StyledFormWrapper>
      </Container>
    </>
  );
};

export default ContactForm;
