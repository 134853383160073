import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import styled from 'styled-components';

const Footer = () => {
  const githubProfileURL = 'https://github.com/odeliafink';
  const linkedInProfileUrl = 'https://www.linkedin.com/in/odeliafink/';
  return (
    <FooterContainer>
      <FlexContainer>
        <LinkColumn style={{ alignItems: 'center' }} className="c1">
          <LinkFooterItem
            target="_blank"
            rel="noopener noreferrer"
            href={githubProfileURL}
          >
            github
            <FaGithub style={{ paddingLeft: '1rem' }} size={35} />
          </LinkFooterItem>
          <LinkFooterItem
            target="_blank"
            rel="noopener noreferrer"
            href={linkedInProfileUrl}
          >
            linkedin
            <FaLinkedin style={{ paddingLeft: '1rem' }} size={35} />
          </LinkFooterItem>
        </LinkColumn>

        <LinkColumn className="c2">
          <TextFooterItem>odelia.finkel@gmail.com</TextFooterItem>
          <TextFooterItem>montreal, qc & remote</TextFooterItem>
          <TextFooterItem>(phone number upon request)</TextFooterItem>
        </LinkColumn>
      </FlexContainer>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <p
          className="copyright"
          style={{
            textAlign: 'center',
            fontFamily: 'Montserrat-Thin',
            color: 'black',
          }}
        >
          © 2023 odelia finkelstein
        </p>
      </div>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  background-color: #f0f0f0;
  padding: 13px;
  border: 7px solid black;
`;

const FlexContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 55%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const LinkColumn = styled.div``;

const LinkFooterItem = styled.a`
  display: flex;
  align-items: center;
  font-family: Montserrat-Regular;
  font-size: 1rem;
  margin: 8px 0;
  text-decoration: none;
`;

const TextFooterItem = styled.p`
  font-family: Montserrat-Regular;
  font-size: 1rem;
  text-align: right;
`;

export default Footer;
