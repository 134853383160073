import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Footer from './components/Footer';
import ContactForm from './components/ContactForm';
import About from './components/About/About';
import SkillsNew from './components/Skills/SkillsNew';

const App = () => {
  return (
    <>
      <Router>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <div id="about">
          <About />
        </div>
        <div id="skills">
          <SkillsNew />
        </div>
        <div id="contact">
          <ContactForm />
        </div>
      </Router>
      <Footer />
    </>
  );
};

export default App;
