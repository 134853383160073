import React from 'react';

import Image2 from '../../images/image2.jpg';
import {
  ImageContainer,
  StyledImage,
  Container,
  Description,
  Title,
  TitleContainer,
  ContentContainer,
} from './styled-components';

const Home = () => {
  return (
    <>
      <Container>
        <ImageContainer>
          <StyledImage src={Image2} />
        </ImageContainer>
        <ContentContainer>
          <TitleContainer>
            <Title>odelia finkelstein</Title>
          </TitleContainer>
          <Description>(fullstack) web developer ++</Description>
        </ContentContainer>
      </Container>
    </>
  );
};

export default Home;
