import styled from 'styled-components';
import { FaArrowRight } from 'react-icons/fa';

export const StyledArrowRight = styled(FaArrowRight)`
  font-size: 3rem;
  @media (max-width: 768px) {
  }
`;

export const StyledSeparator = styled.div`
  border: 1px solid grey;
  margin-inline: 1rem;
`;

export const LowerAboutMeContainer = styled.div`
  display: flex;
  padding-top: 1rem;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-inline: 0rem;
    flex-direction: column;
  }
`;

export const WhatIDoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;

  @media (max-width: 768px) {
    margin-inline: 1rem;
    margin-block: 0.5rem;
    justify-content: center;
  }
`;

export const WhatIDoHeading = styled.div`
  font-size: 3.5rem;
  font-family: Montserrat-Bold;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const LeftH1Heading = styled.h1`
  font-family: Montserrat-Bold;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const LeftAboutColumnContainer = styled.div`
  width: 54%;
`;

export const AboutMeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-block: 1.5rem;
  width: 85%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    margin-inline: 1.1rem;
  }
`;

export const StyledBorder = styled.div`
  border: 2px solid black;
  text-orientation: vertical;
`;

export const RightAboutColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

export const RightH1Heading = styled.h1`
  font-family: Montserrat-Bold;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

export const RightH1HeadingContainer = styled.div``;

export const IconContainer = styled.div``;

export const StyledATag = styled.a`
  transition: color 0.3s; /* Add a transition for smooth color change */

  &:hover {
    color: red; /* Change to the desired color on hover */
  }
`;
