import styled from 'styled-components';

const breakpoints = {
  mobile: '430px',
  tablet: '768px',
};

export const Container = styled.div`
  height: auto;
  position: relative;
`;

export const ImageContainer = styled.div`
  height: auto;
`;

export const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;

  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(to top, rgba(0, 0, 1, 1), rgba(0, 0, 0, 0));

  @media (max-width: 768px) {
    height: 30rem;
    opacity: 0.4;
  }
`;

export const ContentContainer = styled.div`
  position: absolute;
  top: 12rem;
  left: 4rem;
  max-width: fit-content;

  @media (max-width: ${breakpoints.mobile}) {
    border: none;
    top: 8rem;
    left: 1rem;
    margin-right: 0rem;
  }
`;

export const StyledButton = styled.button`
  padding: 1rem 5rem;
  background-color: #273469;
  border: 3px solid black;
  border-radius: 2rem;
  color: white;
  font-weight: bold;

  @media (max-width: 425px) {
    padding: 1rem;
  }
`;

export const TitleContainer = styled.div`
  padding-bottom: 1.4rem;
  width: 70%;

  @media (max-width: ${breakpoints.tablet}) {
    padding-bottom: 1rem;
  }
`;

export const Title = styled.h1`
  font-size: 7rem;
  font-weight: bold;
  color: black;
  line-height: 1;
  font-family: Montserrat-Bold;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 3.2rem;
  }
`;

export const Description = styled.p`
  font-size: 2.5rem;
  line-height: 0;
  color: #14281d;

  font-family: Montserrat-Regular;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1.4rem;
    line-height: 1;
  }
`;
