import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isScrolled }) =>
    isScrolled ? 'rgb(255, 253, 235)' : 'transparent'};
  opacity: ${({ isScrolled }) => (isScrolled ? 0.9 : 'transparent')};
  color: white;
  padding: 0.8rem;
  position: fixed;
  width: 100%;
  z-index: 999;
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    width: auto;
    right: 0;
    background-color: transparent;
  }
`;

export const FaTimesContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 2rem;
    color: white;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  margin-inline: 3rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledLogo = styled(Link)`
  display: none;
`;

export const LogoContainer = styled.div``;

export const StyledLink = styled(Link)`
  color: white;
  margin-right: 2rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: regular;
  font-family: Montserrat-ExtraLight;
  color: black;

  &:hover {
    font-family: Montserrat-Bold;
  }
`;

export const StyledButton = styled.button`
  background-color: transparent;
  color: black;
  border: 1px solid black;
  padding: 0.6rem 1.8rem;
  border-radius: 2rem;
  margin-left: 1rem;
  cursor: pointer;
  font-weight: bold;
  font-family: Montserrat-Regular;

  font-size: 0.9rem;

  &:hover {
    background-color: #56876d;
    color: white;
    transition: 0.3s ease-in;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 2rem;

  color: white;

  @media (min-width: 769px) {
    display: none;
  }
`;

export const DropdownContainer = styled.div`
  display: none;

  @media (max-width: 769px) {
    position: fixed;
    left: 10;
    right: 10px;
    display: flex;
    flex-direction: column;
    margin-left: 22rem;
    text-align: right;
    padding-top: 55px;
    z-index: 1;
  }
`;

export const DropdownLink = styled(Link)`
  color: black;
  padding: 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-family: Montserrat-Bold;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: grey;
  }

  &:nth-child(6) {
    background-color: grey;
    color: white;
    border: 1px dash black;
  }

  @media (max-width: 425px) {
    background-color: white;
    opacity: 0.8;
  }
`;
