export const technologyGroups = [
  {
    name: 'frontend',
    technologies: [
      'javascript',
      'typescript',
      'react',
      'react query',
      'react native',
      'redux / rtk',
      'next.js',
    ],
  },
  {
    name: 'styling',
    technologies: [
      'html',
      'css',
      'sass',
      'styled-components',
      'tailwind css',
      'gsap',
    ],
  },
  {
    name: 'data/cms',
    technologies: [
      'contentful',
      'gatsby',
      'fabric',
      'graphql',
      'mongodb',
      'wordpress',
    ],
  },
  {
    name: 'backend',
    technologies: ['express', 'json db', 'node.js'],
  },
];
