import React, { useState } from 'react';
// import useWindowWidth from '../../lib/utils';
// import { breakpointMaximumSizes } from '../../lib/breakpoints';
import styled from 'styled-components';
import { technologyGroups } from '../../assets/techGroups';
import { FaPlus, FaMinus } from 'react-icons/fa';
import Profile from '../../images/profile.png';
// import gsap from 'gsap';

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const AccordionHeading = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
`;

const AccordionContent = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding: 0.5rem 1rem;
  border-top: 1px solid #ccc;
  transition: height 0.5s ease; /* Apply transition to height */
  height: ${({ isOpen }) =>
    isOpen ? 'auto' : '0'}; /* Set height to 'auto' when open */
  overflow: hidden;
`;

const AccordionIcon = styled.div`
  margin-right: 0.5rem;
`;

const FrameworkGroupContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 0.7fr);

  justify-content: center;
  padding-left: 2rem;
  width: fit-content;

  @media (max-width: 768px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
  }


  }
`;
const FrameworkBox = styled.div`
  @media (max-width: 768px) {
    padding-bottom: 0.3rem;
  }
`;

const FrameworkItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  position: relative;
  padding-left: 3.5rem;

  @media (max-width: 768px) {
    padding-left: 2rem;
    flex-direction: column;
  }
`;

const FrameworkTitleHeading = styled.p`
  font-family: Montserrat-Bold;
  font-size: 2.4rem;
  padding-left: 1rem;
  text-align: left;
  margin-block: 0.7rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-block: 0rem;
  }
`;

const FrameworkTitle = styled.li`
  font-family: Montserrat-Regular;
  font-size: 1.6rem;
  list-style: none;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const H1Heading = styled.h1`
  font-family: Montserrat-Bold;
  font-size: 4rem;
  text-align: right;
  padding-right: 3rem;
  padding-left: 2rem;
  line-height: 3.5rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    text-align: center;

    display: block;
    width: fit-content;
    padding-inline: 0rem;
  }
`;

const H1HeadingContainer = styled.div`
  padding-block: 2rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
    position: absolute;
    top: 116rem;
    padding-block: 0rem;
    color: white;
  }
`;

const LangAndFrameWorkContainer = styled.div`
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    margin-block: 1rem;
  }
`;

const Border = styled.div`
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    padding-bottom: 1rem;
  }
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 40rem;
  height: 51rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    height: 40rem;
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const SkillsNew = () => {
  // const windowWidth = useWindowWidth();
  // const isMobile = windowWidth <= breakpointMaximumSizes.mobile;
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };
  return (
    <>
      <Container>
        <ImageContainer>
          <Image src={Profile} alt="profile" />
        </ImageContainer>
        <LangAndFrameWorkContainer>
          <H1HeadingContainer>
            <H1Heading>languages & frameworks</H1Heading>
          </H1HeadingContainer>
          <Border>
            <div style={{ border: '1px solid black', marginInline: '2rem' }} />
          </Border>
          <FrameworkGroupContainer>
            {technologyGroups.map((frameworkGroup, index) => (
              <AccordionContainer key={index}>
                <AccordionHeading onClick={() => toggleAccordion(index)}>
                  <AccordionIcon>
                    {openAccordion === index ? <FaMinus /> : <FaPlus />}
                  </AccordionIcon>
                  <FrameworkTitleHeading>
                    {frameworkGroup.name}
                  </FrameworkTitleHeading>
                </AccordionHeading>
                <AccordionContent isOpen={openAccordion === index}>
                  <FrameworkBox>
                    {frameworkGroup.technologies.map((technology, idx) => (
                      <FrameworkItemContainer key={idx}>
                        <FrameworkTitle>{technology}</FrameworkTitle>
                      </FrameworkItemContainer>
                    ))}
                  </FrameworkBox>
                </AccordionContent>
              </AccordionContainer>
            ))}
          </FrameworkGroupContainer>
        </LangAndFrameWorkContainer>
      </Container>
    </>
  );
};

export default SkillsNew;
