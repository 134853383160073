import React from 'react';
import useWindowWidth from '../../lib/utils';
import { breakpointMaximumSizes } from '../../lib/breakpoints';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import TwoColumn from './TwoColumn';
import {
  StyledArrowRight,
  StyledSeparator,
  LowerAboutMeContainer,
  WhatIDoContainer,
  WhatIDoHeading,
  LeftH1Heading,
  LeftAboutColumnContainer,
  AboutMeContainer,
  StyledBorder,
  RightAboutColumnContainer,
  RightH1Heading,
  RightH1HeadingContainer,
  IconContainer,
  StyledATag,
} from './styled-components';

const About = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= breakpointMaximumSizes.mobile;
  const isTablet =
    windowWidth > breakpointMaximumSizes.mobile &&
    windowWidth <= breakpointMaximumSizes.tablet;
  const githubProfileURL = 'https://github.com/odeliafink';
  const linkedInProfileUrl = 'https://www.linkedin.com/in/odeliafink/';

  return (
    <>
      <div>
        <AboutMeContainer>
          <LeftAboutColumnContainer>
            <LeftH1Heading>
              as a digital designer, i focus on producing seamless and impactful
              digital experiences by integrating psychology, marketing, and web
              development expertise
            </LeftH1Heading>
          </LeftAboutColumnContainer>
          <StyledBorder />
          <RightAboutColumnContainer>
            <RightH1HeadingContainer>
              <RightH1Heading>(about me)</RightH1Heading>
            </RightH1HeadingContainer>
            <IconContainer>
              <StyledATag
                href={linkedInProfileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin
                  size={isMobile ? 40 : isTablet ? 50 : 70}
                  color="#636940"
                />
              </StyledATag>
              <StyledATag
                href={githubProfileURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGithub
                  size={isMobile ? 40 : isTablet ? 50 : 70}
                  color="#636940"
                />
              </StyledATag>
            </IconContainer>
          </RightAboutColumnContainer>
        </AboutMeContainer>
        <div style={{ marginInline: '1rem' }}>
          <div
            style={{
              border: '2px solid black',
              textOrientation: 'horizontal',
            }}
          />
        </div>
        <LowerAboutMeContainer>
          <WhatIDoContainer>
            <WhatIDoHeading>(what I do)</WhatIDoHeading>
            <StyledArrowRight />
          </WhatIDoContainer>
          <StyledSeparator />
          <div style={{ maxWidth: '40rem', marginInline: 'auto' }}>
            <TwoColumn
              title1={'interactive'}
              title2={'responsive'}
              desc1={
                'expert in interactive design, captivating users with engaging and immersive digital experiences, showcasing creative flair.'
              }
              desc2={
                'seamlessly adapting websites and applications across all devices to ensure smooth functionality and accessibility for a wide audience. '
              }
            />
            <StyledSeparator />
            <TwoColumn
              title1={'fullstack'}
              title2={'branding'}
              desc1={
                'proficient in fullstack development, adeptly handling front and back-end to create comprehensive web sites. '
              }
              desc2={
                'skilled in branding, strategically building and elevating brands through compelling visuals and authentic storytelling.'
              }
            />
          </div>
        </LowerAboutMeContainer>

        <div style={{ paddingTop: '1rem' }}>
          <StyledSeparator />
        </div>
      </div>
    </>
  );
};

export default About;
