import React, { useState, useEffect } from 'react';
import CV from '../../images/CV.pdf';
import { Link } from 'react-scroll';
import { FaHandPaper } from 'react-icons/fa';
import { FaTimes, FaAlignJustify } from 'react-icons/fa';
import { theme } from '../../styles/theme';

import {
  StyledLogo,
  NavContainer,
  LogoContainer,
  StyledLink,
  LinksContainer,
  StyledButton,
  DropdownContainer,
  DropdownLink,
  IconContainer,
} from './styled-components';

export const breakpointMaximumSizes = {
  mobile: 767,
  tablet: 1339,
  desktop: 1420,
};

export const breakpoints = {
  desktop: `(min-width: ${breakpointMaximumSizes.tablet + 1}px)`,
  mobile: `(max-width: ${breakpointMaximumSizes.mobile}px)`,
  tablet: `(max-width: ${breakpointMaximumSizes.tablet}px)`,
};

const NavigationBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDownloadResume = () => {
    const resumeUrl = CV;
    window.open(resumeUrl, '_blank');
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsMenuOpen(false);
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <NavContainer theme={theme} isScrolled={isScrolled}>
        <LogoContainer>
          <StyledLogo to="/">
            <FaHandPaper />
          </StyledLogo>
        </LogoContainer>
        <LinksContainer>
          <StyledLink>
            <Link to="about" smooth={true} duration={500}>
              about
            </Link>
          </StyledLink>
          <StyledLink>
            <Link to="skills" smooth={true} duration={500}>
              skills
            </Link>
          </StyledLink>
          <StyledLink>
            <Link to="contact" smooth={true} duration={500}>
              contact
            </Link>
          </StyledLink>
          <StyledButton onClick={handleDownloadResume}>resume</StyledButton>
        </LinksContainer>
        <IconContainer onClick={handleToggleMenu}>
          {isMenuOpen ? (
            <FaTimes color="black" />
          ) : (
            <FaAlignJustify color="black" />
          )}
        </IconContainer>
      </NavContainer>
      {isMenuOpen && (
        <DropdownContainer>
          <DropdownLink>
            <Link to="about" smooth={true} duration={500}>
              about
            </Link>
          </DropdownLink>
          <DropdownLink>
            <Link to="skills" smooth={true} duration={500}>
              skills
            </Link>
          </DropdownLink>
          <DropdownLink>
            <Link to="contact" smooth={true} duration={500}>
              contact
            </Link>
          </DropdownLink>
          <DropdownLink onClick={handleDownloadResume}>resume</DropdownLink>
        </DropdownContainer>
      )}
    </>
  );
};

export default NavigationBar;
