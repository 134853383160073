import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  margin-block: 1rem;

  div:last-child {
    margin-bottom: 0rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    // Stack columns on mobile and tablet screens
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column; // Stack columns on mobile and tablet screens
  }
`;

const Column = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    margin-bottom: 1rem; // Add some spacing between stacked columns on mobile and tablet screens
  }
`;

const Divider = styled.div`
  border: 1px solid grey;
  margin-inline: 1rem;

  @media (max-width: 768px) {
    display: none; // Hide the divider on mobile and tablet screens
  }
`;

const Title = styled.h1`
  font-family: Montserrat-Bold;

  @media (max-width: 768px) {
    font-size: 2rem;
    text-align: center;
  }
`;

const Description = styled.p`
  font-family: Montserrat-Regular;

  @media (max-width: 768px) {
    font-size: 0.7rem;
    text-align: center;
    margin-inline: 1rem;
  }
`;

const TwoColumn = ({ title1, desc1, title2, desc2 }) => {
  return (
    <Container>
      <FlexContainer>
        <ColumnContainer>
          <RowContainer>
            <Column>
              <div style={{ marginBottom: '.5rem' }}>
                <Title>{title1}</Title>
              </div>
              <Description>{desc1}</Description>
            </Column>

            <Divider />

            <Column>
              <div style={{ marginBottom: '.5rem' }}>
                <Title>{title2}</Title>
              </div>
              <Description>{desc2}</Description>
            </Column>
          </RowContainer>
        </ColumnContainer>
      </FlexContainer>
    </Container>
  );
};

TwoColumn.propTypes = {
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string,
  desc1: PropTypes.string,
  desc2: PropTypes.string,
};

export default TwoColumn;
